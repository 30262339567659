import React, { useRef, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMocked, logoutOnExpiredToken } from '../../constants';
import { setAuthToken, useDebounce, usePaginatedRequest } from '../../utils';

import { handleApiRequest } from '../api'

import { User } from '../models';

const UserContext = React.createContext({});

export function UserProvider({ children }) {
	const [isUserLoading, setLoading] = useState(false);
	const [userApiError, setApiError] = useState(null);
	const [vipUserScores, setVipUserScores] = useState(null);
	const [myFeeds, setMyFeeds] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);
	const [profileUser, setProfileUser] = useState(null);
	const [userRecentActivity, setUserRecentActivity] = useState(null);
	const [follow, setFollow] = useState(false);
	const [userTotalScenariosPlayed, setUserTotalScenariosPlayed] = useState(null);
	const [metaScenariosProVip, setMetaScenariosProVip] = useState([]);
	const [metaScenariosFriends, setMetaScenariosFriends] = useState([]);
	const [metaScenariosMyPosition, setMetaScenariosMyPosition] = useState([]);
	const [lastScoresForFavoriteScenarios, setLastScoresForFavoriteScenarios] = useState(null);
	const [userLastScoresForScenario, setUserLastScoresForScenario] = useState({});
	const [userAllFavoritesScenarios, setUserAllFavoritesScenarios] = useState(null);
  const [userFavoriteGames, setUserFavoriteGames] = useState(null);
	const [showLoginModal, setShowModal] = useState(false);
	const [userCreatorScenarios, setUserCreatorScenarios] = useState(null);
	const [userCreatorPlaylists, setUserCreatorPlaylists] = useState(null);
  const [userCreatorScenarioPlayCount, setUserCreatorScenarioPlayCount] = useState(0)
  const [userCreatorPlaylistSubscribersCount, setUserCreatorPlaylistSubscribersCount] = useState(0)

  const scenarioAllUsersHandler = usePaginatedRequest(User.getMetaScenariosAllUsers, 50)
  const recentVipScoresHandler = usePaginatedRequest(User.getVipUserScores, 50)
  const recentVipLeaderboardScoresHandler = usePaginatedRequest(User.getMetaScenariosProVip, 50)
  const userTotalPlayedScenarios = usePaginatedRequest(User.getTotalPlayedScenarios)
  const scenariosMyPositionHandler = usePaginatedRequest(User.getMetaScenariosMyPosition)
  const scenariosFriendsHandler = usePaginatedRequest(User.getMetaScenariosFriends)
  const benchmarkProgressHandler = usePaginatedRequest(User.getBenchmarksProgress)
  const creatorScenariosHandler = usePaginatedRequest(User.getCreatorScenarios)
  const creatorPlaylistsHandler = usePaginatedRequest(User.getCreatorPlaylists)

	const navigate = useNavigate();

  const clearProfile = () => {
    setUserAllFavoritesScenarios(null)
    setLastScoresForFavoriteScenarios(null)
  }

	function checkAuth() {
    if (isMocked) return true
		if (localStorage.jwtToken) {
			return true;
		} else {
			return false;
		}
	}

  function verifyEmail(token) {
    return User.verifyEmail(token)
  }

	async function getVipUserScores() {
		const response = await User.getVipUserScores();
		if (response.status == 200) {
			setVipUserScores(response.data);
			return;
		}
	}

  function verifyToken() {
    return User.verifyToken()
  }
  
  async function linkDiscord(code) {
    return await User.linkDiscord(code);
  }

  async function unlinkDiscord() {
    return await User.unlinkDiscord();
  }

  function logoutUser() {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('Username');
    localStorage.removeItem('userInfo');
    setCurrentUser(null)
    setLoading(false)
    setAuthToken(null)
    setShowModal({ login: true })
  }

  function handleError(e) {
    if (e.logout && logoutOnExpiredToken) {
      logoutUser()
    } else {
      // logging to error service here
      // console.log('logging error %O', e)
    }
    return e
  }

  async function updateUserInfo(info) {
    // if (info.twitter && typeof info.twitter !== 'undefined') {
    //   const regExp = /(https?\:)?(\/\/)(www[\.])?(twitter.com\/)([a-zA-Z0-9_]{1,15})[\/]?/;
    //   const match = info.twitter.match(regExp);
    //   if (!match) return 'invalid_twitter';
    // }
    // if (info.twitch && typeof info.twitch !== 'undefined') {
    //   const regExp = /^(?:https?:\/\/)?(?:www\.|go\.)?twitch\.tv\/([a-z0-9_]+)($|\?)/;
    //   const match = info.twitch.match(regExp);
    //   if (!match) return 'invalid_twitch';
    // }
    if (!currentUser) setLoading(true);
    const response = await User.updateUserInfo(info);
    if (response) {
			const { status, data } = response;
			if (status == 200) {
				return data;
			} else {
				return false;
			}
    }
    setLoading(false);
  }

	async function getMyFeeds() {
    // if (currentUser) {
      return handleApiRequest(() => User.getMyFeeds())
      .then(({ data }) => {
        setMyFeeds(data)
        return data
      })
      .catch(handleError)
    // } else {
    //   return Promise.resolve()
    // }
	}

  const loadUserInfo = async () => {
    setLoading(true)
    handleApiRequest(() => User.getProfileInfo(), true, true)
    .then((response) => {
      setCurrentUser(response.data)
      localStorage.setItem('userInfo', JSON.stringify(response.data));
      setLoading(false)
    }).catch(e => {
      setLoading(false)
      return handleError(e)
    })
  }

  const getProfileInfoCached = useDebounce(async (forceReload = false) => {
    return new Promise((resolve, reject) => {
      if (!checkAuth() || isUserLoading) {
        // user is logged out, don't load info
        reject();
        return;
      }

      const cachedInfo = localStorage.getItem('userInfo');
      if (!currentUser && cachedInfo) {
        try {
          const parsedCache = JSON.parse(cachedInfo);
          setCurrentUser(parsedCache);
          loadUserInfo();
          resolve();
          return;
        } catch(e) {
          localStorage.removeItem('userInfo')
          reject(e)
        }
      } else if (!currentUser) {
        setLoading(true);
      }
      if (currentUser && !forceReload) {
        resolve(currentUser);
        return;
      } else if (!currentUser || (currentUser && forceReload)) {
        loadUserInfo()
      }
    })
  }, 2000)


	function getProfileInfo(force = false) {
    return getProfileInfoCached(force)
	}

	function followUser(id, follow) {
		if (!checkAuth()) return Promise.reject()
    return User.followUser(id, follow)
	}

	async function checkFollowUser(id) {
    // don't do checkFollowUser for own id
    if (currentUser && currentUser.playerId === id) {
      return
    }

    handleApiRequest(() => User.checkFollowUser(id))
    .then(result => {
      if (result.status === 200) {
        setFollow(result.data.following)
      }
    })
    .catch(handleError)
	}

  const getRecentActivityDebounced = useDebounce(async (username) => {
		const response = await User.getRecentActivity(username);
		const { status, data } = response;
    if (status == 200) {
			setUserRecentActivity(data);
		}
  }, 2000)

	function getRecentActivity(username) {
    getRecentActivityDebounced(username)
	}

	async function getTotalScenariosPlayed(username) {
		const response = await User.getTotalScenariosPlayed(username);
		const { status, data, message } = response;
		if (status == 200) {
			setUserTotalScenariosPlayed(data);
		} else {
			setApiError(message);
		}
	}

  async function getMetaScenariosProVip(id) {
    const response = await User.getMetaScenariosProVip(id);
		const { status, data } = response;
    if (status == 200) {
      setMetaScenariosProVip(data);
    }
  }

	async function getMetaScenariosFriends(id) {
		if (!checkAuth()) return;
		const response = await User.getMetaScenariosFriends(id);
		const { status, data } = response;
    if (status == 200) {
				setMetaScenariosMyPosition(data.data);
		}
	}

	async function getMetaScenariosMyPosition(id) {
		if (!checkAuth()) return;
		const response = await User.getMetaScenariosMyPosition(id);
		const { status, data } = response;
    if (status == 200) {
			setMetaScenariosFriends(data.data);
		}
	}

  // TODO: set here
	async function getUserAllFavoritesScenarios(username) {
		// if (!checkAuth()) return;
		const response = await User.getUserAllFavoritesScenarios(username);
		const { status, data } = response;
    if (status == 200) {
      const getTemp = () => ({ name: '', leaderboardId: null, score: null, data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] })
      let arr = [];
      let chartValue = { data: [] };

      for (let i = 0; i < data.length; i++) {
        const { scenarioName, leaderboardId, score, scoreHistory } = data[i]
        let temp = getTemp()
        temp.name = scenarioName
        temp.leaderboardId = leaderboardId
        temp.score = score
        let index = 0;
        for (const item of scoreHistory) {
          temp.data[index] = item.score;
          index++;
        }
        arr.push(temp);
      }

      chartValue.data = arr;

      // console.log(`setting favorites %O, chartValue %O`, data, chartValue)
			setUserAllFavoritesScenarios(data);
      setLastScoresForFavoriteScenarios(chartValue)
      return response
		}
	}

  async function getUserFavoriteGames(username) {
    const response = await User.getFavoriteGames(username);
    const { status, data } = response;
    if (status == 200) {
      setUserFavoriteGames(data);
    }
  }

  function getAllCompletedScenariosBySteamId(steamId) {
    const response = User.getAllCompletedScenariosBySteamId(steamId)
    return response
  }

  const getProfileByUsernameDebounced = useDebounce(async (username) => {
    return handleApiRequest(() => User.getProfileByUsername(username))
    .then(({ data }) => {
      setProfileUser(data)
    })
    .catch(handleError)
  }, 2000)

  const getProfileByUsername = (username, force = false) => {
    if (force) {
      return handleApiRequest(() => User.getProfileByUsername(username))
      .then((response) => {
        const { data } = response
        setProfileUser(data)
        return response
      })
      .catch(handleError)
    } else {
      return getProfileByUsernameDebounced(username)
    }
  }

  async function logout() {
    setCurrentUser(null);
  }

  async function addVideo(index, id, platform) {
    const response = await User.addVideo(index, id, platform);
    return response
    // const { status, data } = response;
    // if (status == 200) {

    // 	// setUserCreatorScenarios({ list, totalPlays });
    // }
  }

  async function deleteVideo(index) {
    const response = await User.deleteVideo(index)
    return response
    // const { status, data } = response;
    // if (status == 200) {

    // 	// setUserCreatorScenarios({ list, totalPlays });
    // }
  }

	async function getCreatorScenarios(username) {
		const response = await User.getCreatorScenarios(username);
		const { status, list, totalPlays } = response;
    if (status == 200) {
			setUserCreatorScenarios({ list, totalPlays });
		}
	}

	async function getCreatorPlaylists(username) {
		const response = await User.getCreatorPlaylists(username);
		const { status, list, totalSubscribers } = response;
    if (status == 200) {
			setUserCreatorPlaylists({ list, totalSubscribers });
		}
	}

	async function setVideos(videos) {
		if (!checkAuth()) return;
		const response = await User.setVideos(videos);
		const { status } = response;
    return !!status == 200
	}

	async function getLastScoresForFavoriteScenarios(favorites, username) {
		// if (!checkAuth()) return;
    if (!favorites) return

    // console.log(`provider loading scores from favorites %O`, favorites)

		let arr = [];
		let result;
		let max = 100;
		let chartValue = { max: max, data: [] };

    let requests = []
    let scenarios = []

		for (const favoriteScenario of favorites) {
			const { scenarioName, leaderboardId } = favoriteScenario;
      scenarios.push({ scenarioName, leaderboardId })
      requests.push(User.getLastScoresForScenarioByName(scenarioName, username))
    }
    await Promise.all(requests).then(results => {
      for (let i = 0; i < results.length; i++) {
        const response = results[i]
        const { status, data, message } = response
        if (status == 200) {
          result = data;
          let { scenarioName, leaderboardId } = scenarios[i]
          let temp = { name: scenarioName, leaderboardId, data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] };
          let index = 0;
          for (const item of result) {
            if(item.score >= max)
              max = item.score
              // max = Math.ceil(item.score) * 100;
            temp.data[index] = item.score;
            index++;
          }
          arr.push(temp);
        } else {
          setApiError(message);
          break;
        }
      }
    })

		chartValue.max = max;
		chartValue.data = arr
    console.log(`loaded lastScoresFavoriteScenarios %O`, chartValue)
		setLastScoresForFavoriteScenarios(chartValue);
	}

	async function getLastScoresForScenario(username, leaderboardId, leaderboardName) {
    // console.log(`getLastScoresForScenario username %s, leaderboard`, username, leaderboardName)
		// if (!checkAuth()) return;
		let result;
		let max = 100;
		let chartValue = { max: max, data: {} };
		let temp = { name: leaderboardName, chartData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], data: [] };
		const response = await User.getLastScoresForScenario(
			username,
			leaderboardId
		);
		const { status, data, message } = response;
		if (status == 200) {
			result = data;
			temp.data = result;
			let index = 0;
			for (const item of result) {
				if(item.score >= max)
					max = Math.ceil(item.score) * 100;
				temp.chartData[index] = item.score;
				index++;
			}
			for (let i = 0; i < 10; i++) {
				temp.chartData[i] = 140 * (temp.chartData[i] / max);
			}
		} else {
			setApiError(message);
		}
		chartValue.max = max;
		chartValue.data = temp;
		console.log(`GET LAST SCORES FAV %O`, chartValue)
		setUserLastScoresForScenario(chartValue);
	}

  async function updateUsername(username) {
    const response = await User.updateUsername(username)
    if (response.status === 200) {
      localStorage.setItem('Username', username)
      return { success: true }
    } else {
      return { success: false, message: response.response.data.error }
    }
  }

	async function getLastScoresForScenarioByName(scenarioName, username) {
		// if (!checkAuth()) return;
		let result;
		let max = 100;
		let chartValue = { max: max, data: {} };
		let temp = { name: scenarioName, chartData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], data: [] };
		const response = await User.getLastScoresForScenarioByName(
			scenarioName,
			username
		);
		const { status, data, message } = response;
		if (status == 200) {
			result = data;
			temp.data = result;
			let index = 0;
			for (const item of result) {
				if(item.score >= max)
					max = Math.ceil(item.score) * 100;
				temp.chartData[index] = item.score;
				index++;
			}
			for (let i = 0; i < 10; i++) {
				temp.chartData[i] = 140 * (temp.chartData[i] / max);
			}
		} else {
			setApiError(message);
		}
		chartValue.max = max;
		chartValue.data = temp;
		setUserLastScoresForScenario(chartValue);
	}

	function changeModalVisible() {
		setShowModal(false);
	}

	const memoedValue = React.useMemo(
		() => ({
			isUserLoading,
			userApiError,
			vipUserScores,
			myFeeds,
			currentUser,
			profileUser,
			follow,
			userRecentActivity,
			userTotalScenariosPlayed,
      userTotalPlayedScenarios,
			metaScenariosProVip,
			metaScenariosFriends,
			metaScenariosMyPosition,
			userAllFavoritesScenarios,
      userFavoriteGames,
			lastScoresForFavoriteScenarios,
			userLastScoresForScenario,
			showLoginModal,
			userCreatorScenarios,
			userCreatorPlaylists,
			getLastScoresForFavoriteScenarios,
			getLastScoresForScenario,
			getLastScoresForScenarioByName,
			updateUserInfo,
			getVipUserScores,
			getMyFeeds,
			getProfileInfo,
			getRecentActivity,
			getTotalScenariosPlayed,
      scenarioAllUsersHandler,
			getMetaScenariosProVip,
			getMetaScenariosFriends,
			getMetaScenariosMyPosition,
			getUserAllFavoritesScenarios,
      getUserFavoriteGames,
			getProfileByUsername,
      updateUsername,
			logout,
			followUser,
			checkFollowUser,
			checkAuth,
			setVideos,
			changeModalVisible,
			getCreatorScenarios,
			getCreatorPlaylists,
      recentVipScoresHandler,
      scenariosMyPositionHandler,
      scenariosFriendsHandler,
      benchmarkProgressHandler,
      setUserLastScoresForScenario,
      addVideo,
      deleteVideo,
      getAllCompletedScenariosBySteamId,
      setShowModal,
      loadUserInfo,
      linkDiscord,
      unlinkDiscord,
      verifyToken,
      logoutUser,
      recentVipLeaderboardScoresHandler,
      clearProfile,
      verifyEmail,
      creatorScenariosHandler,
      creatorPlaylistsHandler,
      userCreatorScenarioPlayCount,
      userCreatorPlaylistSubscribersCount,
      setUserCreatorScenarioPlayCount,
      setUserCreatorPlaylistSubscribersCount,
      User
		}),
		[
			isUserLoading,
			userApiError,
			vipUserScores,
			myFeeds,
			follow,
			currentUser,
			profileUser,
			userRecentActivity,
			userTotalScenariosPlayed,
      userTotalPlayedScenarios,
      scenarioAllUsersHandler,
			metaScenariosProVip,
			metaScenariosFriends,
			metaScenariosMyPosition,
			userAllFavoritesScenarios,
			lastScoresForFavoriteScenarios,
			userLastScoresForScenario,
			showLoginModal,
			userCreatorScenarios,
			userCreatorPlaylists,
      recentVipScoresHandler,
      scenariosMyPositionHandler,
      scenariosFriendsHandler,
      benchmarkProgressHandler,
      setUserLastScoresForScenario,
      addVideo,
      deleteVideo,
      getAllCompletedScenariosBySteamId,
      setShowModal,
      loadUserInfo,
      linkDiscord,
      unlinkDiscord,
      verifyToken,
      logoutUser,
      recentVipLeaderboardScoresHandler,
      clearProfile,
      verifyEmail,
      creatorScenariosHandler,
      creatorPlaylistsHandler,
      userCreatorScenarioPlayCount,
      userCreatorPlaylistSubscribersCount,
      setUserCreatorScenarioPlayCount,
      setUserCreatorPlaylistSubscribersCount,
      User
		]
	);

	return (
		<UserContext.Provider value={memoedValue}>
			{children}
		</UserContext.Provider>
	);
};

export function useUser() {
	return React.useContext(UserContext);
}
